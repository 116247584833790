import { compose, defaultProps, withHooks } from "enhancers";
import { Box } from "components";
import { DatePicker as FormikMuiDatePicker } from "formik-material-ui-pickers";
import { format } from "date-fns";

import { ReactComponent as DatePickerIcon } from "assets/icon/datepicker_icon.svg";

const DatePicker = (props) => (
  <Box position="relative">
    <FormikMuiDatePicker {...props} />
    <Box position="absolute" top={16} right={12}>
      <DatePickerIcon />
    </Box>
  </Box>
);

const enhancer = compose(
  defaultProps({ format: "dd/MM/yyyy", placeholder: "00/00/0000" }),
  withHooks((props) => {
    return {
      ...props,
      labelFunc: (date, invalidLabel) =>
        props.field.value ? format(date, props.format) : "",
    };
  })
);

export default enhancer(DatePicker);
